@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@300;400&display=swap');

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #8d8b8d #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #8d8b8d;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

h1 {
    font-family: 'Blinker', sans-serif !important;
    font-weight: 400 !important;
    font-size: 55px !important;
    /* color: ; */
}

h2 {
    font-family: 'Blinker', sans-serif !important;
    font-weight: 400 !important;
    font-size: 36px !important;
    color: #373737;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 46px);
    position: relative;
    top: 0px;

}
.auth-wrapper{
    overflow: hidden;
}
.login-error{
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    color:crimson
}

.login-video {
    height: 200px;
    width: 300px;
}

.login-inner-video-wrapper {
    background-color: #1b1b1b;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.login-inner-bottom-wrapper {
    padding: 20px;
}

.login-wrapper {
    width: 400px;
    height: 600px;
    /* padding: 40px; */
    background-color: white;
    opacity: 1;
    z-index: 9999;
    position: relative;
    border-radius: 5px;
}

.resources {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.carosel-video-wrappers {
    height: calc(100vh - 46px);
    width: 100%;
    position: absolute;
}

.carosel-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.video-filter {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    opacity: 0.8;
    background-color: black;
}

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 600px;
    /* padding-left: 100px;
    margin-left: 100px; */
}

.form-container-assets {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding-left: 100px;
    margin-left: 100px; */
}

.form-container-assets .ant-form {
    width: 100%;
}

.resource-card {
    max-height: 500px;
    overflow: scroll;
}

.container {
    width: 100%;
    display: flex;
    align-self: center;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    min-height: 720px;
    /* background-color: #eeeeee; */
}

.modal-inner .modal-left {
    display: flex;
    flex-grow: 4;
    padding: 20px;
    flex-direction: column;
    /* background-color: #fff; */
}

.modal-inner .modal-left .in-house-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal-inner .modal-left .in-house-toggle label {
    padding-left: 5px;
}

.modal-inner .modal-right {
    background-color: #eeeeee;
    display: flex;
    flex-grow: 1;
    padding: 20px;
    padding-bottom: 0px;
    flex-direction: column;
    border-top-right-radius: 10px;
    justify-content: space-between;
}

.modal-inner .modal-right .steps-assignee-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-inner .modal-right .steps-assignee-wrapper .ant-select {
    position: relative;
    min-width: 100px;
    top: -15px;
    right: 20px;
}

.modal-inner .modal-right .steps-assignee-wrapper .ant-select .ant-select-selection-item {
    min-width: 100px;
}


.create-asset-modal .ant-modal-content {
    padding: 0px;
}

.create-asset-modal .ant-modal-header {
    padding: 24px 20px;
}

.create-asset-modal .ant-modal-footer {
    padding: 0px 20px;
    margin-top: 0px;
    /* padding-top: 0px; */
}

.button-wrapper {
    position: relative;
    bottom: 0px;
    width: 100%;
}

.button-wrapper .inner {
    display: flex;
    justify-content: space-between;
}

.button-wrapper .inner .confirm-btns {
    display: flex;
    justify-content: space-between;
}


.tags-wrapper {
    max-width: 600px;
}

.modal-button-wrapper {
    display: flex;
    width: 1200px;
    justify-content: flex-end;
    padding: 30px 30px;
}

.modal-button-wrapper-inner {
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: space-around;
}

.home-card {
    margin-top: 50px;
}

.home-card .ant-card-cover {
    display: flex;
    justify-content: center;
}

.home-card img {
    width: 150px !important;
}

.quicklink-cards-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.quicklink-cards-wrapper .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 1400px;
    /* min-height: 100vh; */
    align-content: space-evenly;
}

.quicklink-img {
    border-radius: 100px !important;
    border: 2px solid #ccc;
}

.quicklinks-card {
    margin-left: 15px;
}

.nav-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 600px;
}

.create-asset-modal .ant-modal-footer{
    background-color: #eeeeee;
    padding-top: 20px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.select-container.ant-form-item-control-wrapper {
    width: 100%;
  }